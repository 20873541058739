import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/About"

const AboutUs = () => {
  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts/about-hubble/",
      "@type": "WebPage",
      "name": "Learn About Hubble",
      "description": "We believe buying contact lenses and glasses should be simple.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    },
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      "name": "Hubble",
      "url": "https://hubblecontacts.com",
      "sameAs": [
         "https://www.facebook.com/HubbleContacts/",
         "https://twitter.com/hubblecontacts",
         "https://www.instagram.com/hubblecontacts/"
      ],
      "logo": "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg",
      "image": "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg",
      "description": "The more affordable daily contact lens. We founded Hubble with a simple mission: offer contact lens wearers the high quality and affordable lenses they deserve.",
      "contactPoint" : [
        {
          "@type" : "ContactPoint",
          "telephone" : "+1-844-334-1640",
          "contactType" : "customer service"
        }
      ]
    }
  ]

  return (
    <Layout>
      <SEO title="About Hubble Contacts Company: Our Story & Mission"
        description="Hubble Contacts is dedicated to providing our customers with the best in eye care and vision solutions. Learn more about Hubble's mission and company history in the eye care industry."
        schemaMarkup={schemaMarkup} />
      <About/>
    </Layout>
  )
}

export default AboutUs
