import React from "react"
import './style.scss'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import WebpImage from "../WebpImage"

const About = () => {
  return (
    <div className="about-us ">
      <div className=" top-section">
        <div className="top-section--background">
          <WebpImage className="desktop-image" wrapperClassName="desktop-image" fileName="Pages/aboutUs/About-Us-Hero-Module-Desktop.jpg" />
          <WebpImage className="mobile-image" wrapperClassName="mobile-image" fileName="Pages/aboutUs/About-Us-Hero-Module-Mobile.jpg" />
        </div>
        <div className="grid-section">
          <h1>About Us</h1>
        </div>
      </div>
      <div className="see-easy-section ">
        <div className="lens-section">
          <div className="desktop-only">
            <StaticImage src="../../images/Pages/aboutUs/Hubble-About-Us-Intro-Module-Desktop.png" alt="contacts boxes and glasses" />
          </div>
          <div className="mobile-only">
            <StaticImage src="../../images/Pages/aboutUs/Hubble-About-Us-Intro-Module-Mobile.png" alt="contacts boxes and glasses" />
          </div>
        </div>
        <div className="copy-section" >
          <h2>See, <i>Easy.</i></h2>
          <p className="subheader">We believe buying contact lenses and glasses should be simple. Full stop. That’s why our goal is to deliver <i>the easiest, most hassle-free contact lens and glasses experience in the world.</i></p>
          <p className="copy">
            Here’s how we see it at Hubble Contacts. If you’re spending all your time arguing with customer service reps, endlessly searching for the right pair of glasses, or trying to remember to reorder your contacts before they run out (again), you’re not focused on all the things the world has to offer.
            <br/>
            <br/>
            Like that incredible sunset outside your window, the text convos on your phone that make you laugh (until you cry), or the gummy, toothless grin smiling at you from across her highchair.
            <br/>
            <br/>
            Hubble Contacts was created out of the desire to make contact lenses accessible and affordable to as many people as possible. Over the years, we have continued to evolve and adapt to the changing needs of our customers, and expand our product line along the way to also include high quality eyeglasses and sunglasses. With a passion for eye care and a dedication to customer service, our mission remains making a difference in the industry.
            <br/>
            <br/>
            So, let Hubble’s world-class team take care of you — and your contacts and glasses.
            <br/>
            <br/>
            And you? You keep your eyes on the prize.
          </p>
        </div>
      </div>

      <div className="grid-section" id="our-contacts" >
        <div className="our-contact-lenses">
          <h2>Our Contact Lenses</h2>
          <p className="subheader">With no-fuss subscription personalization, home delivery, and hassle-free policies, we make it easy to get quality contacts right when you need them.</p>
          <div className="our-contact-lenses--image-wrapper">
            <StaticImage objectFit="contain" src="../../images/Pages/aboutUs/About-Us-Contact-Lenses-Module.png" alt="contact lens boxes" />
          </div>
          <div className="row values">
            <div className="col-sm-12 col-md-4 ">
              <div className="circle-number"><span>1</span></div>
              <p className="title">Personalized for You</p>
              <p>You decide on the refill frequency that’s right for you, based on your wear schedule. Take control of your delivery cadence in the member portal.</p>
            </div>
            <div className="col-sm-12 col-md-4 ">
              <div className="circle-number"><span>2</span></div>
              <p className="title">Quality and comfort</p>
              <p>Our disposable contact lenses are designed for all-day comfort with a trim, thin edge, making them extra easy to pop in and out. No futzing necessary.</p>
            </div>
            <div className="col-sm-12 col-md-4 ">
              <div className="circle-number"><span>3</span></div>
              <p className="title">Made with care</p>
              <p>Every set of lenses passes a multi-layer inspection that’s super tight and refreshingly thorough.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-section" id="our-glasses">
        <div className="eyeglasses-sunglasses-section vertical-margin">
          <h2>Our Eyeglasses and Sunglasses</h2>
          <p className="subheader">With high-quality lenses that stand up to wear and tear and stylish, on-trend frames, we deliver the look you want for less every. single. time.</p>
          <div className="glasses-wrapper">
            <StaticImage src="../../images/Pages/aboutUs/About-Us-Glasses-Module.png" alt="Glasses and Sunglasses Frames by Hubble" />
          </div>
          <div className="row values">
            <div className="col-sm-12 col-md-4 value">
              <div className="circle-number"><span>1</span></div>
              <p className="title">Quality and comfort</p>
              <p>Our glasses and sunglasses come with impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush.</p>
            </div>
            <div className="col-sm-12 col-md-4 value">
              <div className="circle-number"><span>2</span></div>
              <p className="title">Personalized just for you</p>
              <p>Choose from anti-reflective, anti-glare, anti-scratch, hydrophobic, and oleophobic coatings. Plus, you can add blue light filtering on all of our glasses and sunglasses.</p>
            </div>
            <div className="col-sm-12 col-md-4 value">
              <div className="circle-number"><span>3</span></div>
              <p className="title">Made with care</p>
              <p>Every single pair of glasses and sunglasses is hand-assembled and made to order.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="happiness-section">
        <div className="row">
          <div className="img-wrapper">
            <StaticImage src="../../images/Pages/aboutUs/About-Us-Happiness-Guarantee.jpg" alt="smiling woman with sunglasses" />
          </div>
          <div className="copy-section">
            <div className="title-wrapper">
            <div className="smiley-wrapper">
              <StaticImage src="./assets/cookie.png" alt="" />
            </div>
            <h2>Our Happiness Guarantee</h2>
            </div>
            <p className="subheader">We <i>see</i> what you want.</p>
            <p>Love it or we’ll cover it! You can return any unopened boxes or frames within 14 days for a full refund. That’s <i>us</i> looking out for <i>you</i>.</p>
            <div className="button-wrapper">
              <Link to={"/contact-lenses/hubble"} className="btn btn-primary cta-btn">Get a Box Now for Just $1</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
